import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import React, { ComponentType, useEffect, useState } from 'react';

import { useAbortableEffect } from 'src/utils/hooks/use-abortable';

import PageLoading from '../../common/page-loading/page-loading';
import { useStores } from '../../common/root-store-provider/root-store-provider';

const page = <T extends object>(Component: ComponentType<T>) =>
  observer((props: T) => {
    const [mounted, setMounted] = useState(false);
    const { authStore } = useStores();
    const router = useRouter();

    const emailToken =
      typeof router.query['email_token'] === 'string'
        ? router.query['email_token']
        : undefined;

    useEffect(() => {
      setMounted(true);
    }, []);
    useAbortableEffect(() => {
      if (emailToken === undefined) {
        // noinspection JSIgnoredPromiseFromCall
        authStore.fetchUser();
      }
    }, [authStore]);

    // The check on `mounted` helps avoid hydration DOM mismatch
    // that happens because `user` is always empty during SSR,
    // but may be `null` if the user is not logged in
    return !mounted || authStore.user === undefined ? (
      <PageLoading />
    ) : (
      <Component {...props} />
    );
  });

export default page;
