import React from 'react';

import LoaderLogo from '../../../../public/icons/loader-logo.svg';

const PageLoading = () => {
  const injectStyles = `
  html {
    min-height: 100%;
  }
  body,
  #__next {
    flex: 1;
  }
  html,
  body,
  #__next {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }
  @keyframes pulse {
      20% { transform: translateY(-50%) scale(1) }
      80% { transform: translateY(-50%) scale(1.1) }
    }`;

  return (
    <>
      <style>{injectStyles}</style>
      <div
        style={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'transparent',
        }}>
        <LoaderLogo
          width={146}
          height={146}
          alt="logo"
          style={{
            position: 'absolute',
            top: '50%',
            animation: 'pulse 1s infinite',
            transform: 'translateY(-50%)',
          }}
        />
      </div>
    </>
  );
};

export default PageLoading;
